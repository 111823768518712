import * as React from "react";
import { cn } from "@/lib/utils";
import { MdInfo } from "react-icons/md";

const Textarea = React.forwardRef(({ label,register, className,icon, ...props }, ref) => {
  return (
    <div className={ ` flex flex-col gap-1`}>
      <span className="flex justify-between items-center">
      {label && <label className="text-[#09090B]  w-full text-right text-[14px] font-[500]">{label}</label>}
      {icon && <MdInfo color="#DFDFDF" className="cursor-pointer" size={20}/>}
      </span>
      <textarea
      {...(register && name && { ...register(name) })}
        className={cn(
          "flex min-h-[80px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm outline-offset-4 outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50",
        )}
        ref={ref}
        {...props}
      />
    </div>
  );
});
Textarea.displayName = "Textarea";

export { Textarea };