import * as React from 'react';
import Image from 'next/image';
import { X } from 'lucide-react';
import { MdBookmarkBorder } from 'react-icons/md';

import { truncateString } from '@/lib/utils';
import { Card, CardContent } from '@/components/ui/card';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/ui/carousel';

import ArticleActionDropDownList from './ArticleActionDropDownList';

// import { Progress } from '@/components/ui/progress';

export function UploadMediaCarousel({
  image,
  removeImage,
  video,
  removeVideo,
}) {
  return (
    <div>
      <Carousel
        opts={{ direction: 'rtl' }}
        className="w-full  lg:max-w-full bg-white"
      >
        <CarouselContent className=" bg-white gap-3 mr-6" dir="rtl">
          {image?.map((image, index) => (
            <CarouselItem
              key={index}
              className=" basis-[184px] lg:basis-[184px]  bg-white"
            >
              <div className="relative mt-4 ">
                <Image
                  src={image}
                  alt="Uploaded"
                  width={0}
                  height={0}
                  unoptimized={true}
                  sizes="100%"
                  className="w-full rounded-lg object-cover h-[200px] "
                  loading="lazy"
                />
                <button
                  className="absolute top-2 right-2 bg-gray-800 text-white p-1 rounded-full"
                  onClick={() => removeImage(image)}
                >
                  <X size={18} />
                </button>
              </div>
            </CarouselItem>
          ))}
          {video && (
            <CarouselItem className=" basis-[184px] lg:basis-[184px]  ">
              <div className="relative mt-4 bg-gray-100 rounded-lg">
                <video
                  src={video}
                  controls
                  className="w-full h-[200px] rounded-lg "
                />
                <button
                  className="absolute top-2 right-2 bg-gray-800 text-white p-1 rounded-full"
                  onClick={() => removeVideo(video)}
                >
                  <X size={18} />
                </button>
              </div>
            </CarouselItem>
          )}
        </CarouselContent>
        <CarouselPrevious className="hidden md:flex" />
        <CarouselNext className="hidden md:flex" />
      </Carousel>
    </div>
  );
}

export default function CustomCard({
  image,
  description,
  authorName,
  authorImage,
  readLink,
}) {
  return (
    <div
      className="relative w-[184px] h-[211px] lg:w-[184px] rounded-[6px] shadow-lg border border-[#F5F5F5] overflow-hidden bg-white"
      dir="rtl"
    >
      {/* Close Button */}
      <button className="absolute top-2 left-2 z-10 bg-white/80 p-1 rounded-full shadow">
        <X size={18} className="text-gray-700" />
      </button>

      {/* Image */}
      <div className="relative w-full h-[121px]">
        <img src={image} alt="cover" className="w-full h-full object-cover" />
        {/* Optional: Add a puzzle texture overlay */}
        <div className="absolute inset-0 bg-[url('/path-to-puzzle-texture.png')] opacity-20"></div>
      </div>

      {/* Card Content */}
      <div className="px-3 py-1">
        <p className="text-xs text-[#4D5861] font-normal">
          {truncateString(description, 45)}
        </p>

        {/* Author Info */}
        <div className="flex items-center gap-2 mt-3 justify-between">
          <div className="flex items-center gap-2">
            <img
              src={authorImage}
              alt={authorName}
              className="size-[18px] rounded-full border"
            />
            <span className="text-xs text-[#A0A0A0] font-normal">
              {authorName}
            </span>
          </div>
          <a
            style={{ textDecoration: 'underline' }}
            className="text-xs text-[#7D45B7] font-normal"
          >
            قراءة
          </a>
        </div>

        {/* Read Button */}
        <a
          href={readLink}
          className="text-xs text-[#7D45B7] font-normal mt-2 inline-block "
        >
          قراءة
        </a>
      </div>
    </div>
  );
}
