import { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { useGetArticleByIdQuery } from '@/RTK/services/articleApi';
import { useCreatePostMutation } from '@/RTK/services/postsApi';
import {
  useDeleteFileMutation,
  useGetPresignedUrlMutation,
} from '@/RTK/services/s3Api';
// Import the RTK mutation
import { useGetUserQuery } from '@/RTK/services/userApi';
import { DialogTitle } from '@radix-ui/react-dialog';
import {
  Bold,
  Image as ImageIcon,
  Link,
  Video as VideoIcon,
  X,
} from 'lucide-react';
import { AiOutlineRetweet } from 'react-icons/ai';

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Textarea } from '@/components/ui/textarea';

import Loader from '../../../../../utils/Loader';
import { UploadMediaCarousel } from './uploadMediaCarousel';

export function RepostArticleDialog({ dialogOpen, setDialogOpen, id }) {
  const [content, setContent] = useState('');
  const [images, setImages] = useState([]); // Store uploaded image URLs
  const [video, setVideo] = useState(null); // Store uploaded video URL
  const [isUploadingMedia, setIsUploadingMedia] = useState(false);

  // Add these new states
  const [articlePreview, setArticlePreview] = useState(null);
  const [articleId, setArticleId] = useState(id);

  const textareaRef = useRef(null);

  // RTK Mutations
  const [createPost, { isLoading: isPosting }] = useCreatePostMutation();
  const [getPresignedUrl] = useGetPresignedUrlMutation();
  const [deleteFile] = useDeleteFileMutation();
  const { data: user, isLoading: userLoading } = useGetUserQuery(undefined);

  // Add the article query
  const {
    data: articleData,
    isLoading: isLoadingArticle,
    error: articleError,
  } = useGetArticleByIdQuery(articleId, {
    skip: !articleId,
  });

  // Function to get a pre-signed URL and upload a file to S3
  const uploadFileToS3 = async (file) => {
    try {
      // Step 1: Get a pre-signed URL
      const { url, fileUrl } = await getPresignedUrl(file.type).unwrap();

      // Step 2: Upload the file to S3
      await fetch(url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      });

      // Step 3: Return the uploaded file URL
      return fileUrl;
    } catch (error) {
      console.error('Error uploading file to S3:', error);
      return null;
    }
  };

  // Function to handle image selection and upload
  const handleImageUpload = async (event) => {
    const files = event.target.files;
    if (files.length) {
      setIsUploadingMedia(true);
      const uploadedUrls = await Promise.all(
        Array.from(files).map((file) => uploadFileToS3(file))
      );
      setIsUploadingMedia(false);
      setImages((prevImages) => [
        ...uploadedUrls.filter(Boolean),
        ...prevImages,
      ]);
    }
    event.target.value = null;
  };

  // function to remover video from s3
  const removeVideoFromS3 = async (videoUrl) => {
    try {
      const key = videoUrl.split('.com/')[1]; // Extract S3 object key
      deleteFile(key).unwrap();
    } catch (error) {
      console.error('Error deleting video file:', error);
    } finally {
      setVideo(null);
    }
  };

  // Function to handle video selection and upload
  const handleVideoUpload = async (event) => {
    if (video) {
      removeVideoFromS3(video);
    }

    const file = event.target.files[0];
    if (file) {
      setIsUploadingMedia(true);
      const uploadedUrl = await uploadFileToS3(file);
      setVideo(uploadedUrl);
    }
    event.target.value = null;
    setIsUploadingMedia(false);
  };

  const removeImageFromS3 = async (imageUrl) => {
    try {
      const key = imageUrl.split('.com/')[1]; // Extract S3 object key
      await deleteFile(key).unwrap();
      setImages((prevImages) => prevImages.filter((img) => img !== imageUrl));
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  // Function to remove an image from the list
  const removeImage = async (imageUrl) => {
    // Remove image from S3
    removeImageFromS3(imageUrl);

    // Remove the image from the list
    setImages((prevImages) => prevImages.filter((img) => img !== imageUrl));
  };

  useEffect(
    function closeDialogOnPostCreation() {
      if (!isPosting) {
        setDialogOpen(false);
      }
    },
    [isPosting]
  );

  // Function to create a post
  const handleCreatePost = async () => {
    try {
      const response = await createPost({
        content: {
          text: content,
          images,
          video,
          articleId,
        },
      }).unwrap();

      setContent('');
      setImages([]);
      setVideo(null);
      setArticleId(null);
      setArticlePreview(null);
      textareaRef.current.focus();
    } catch (error) {
      console.error('Error creating post:', error);
    } finally {
      setDialogOpen(false);
    }
  };

  // Function to close the dialog it deletes any video or images that are uploaded to aws on closing the dialog
  function handleCloseDialog() {
    setContent('');
    setArticleId(null);
    setArticlePreview(null);

    if (video) removeVideoFromS3(video);
    setVideo(null);
    images.forEach((image) => removeImageFromS3(image));
    setImages([]);
    setDialogOpen(false);
  }

  // Add paste handler
  const handlePaste = async (e) => {
    if (articleId) return;
    const pastedText = e.clipboardData.getData('text');
    const match = pastedText.match(
      /https:\/\/kitabh\.com\/view-article\/([a-f0-9]+)/
    );

    if (match) {
      const id = match[1];
      setArticleId(id);
    }
  };

  useEffect(() => {
    if (articleData) {
      setArticlePreview(articleData?.article);
    }
  }, [articleData]);

  useEffect(() => {
    if (id) {
      setArticleId(id);
    }
  }, []);

  // Add preview removal handler
  const removeArticlePreview = () => {
    setArticlePreview(null);
    setArticleId(null);
  };

  // Add preview component
  const ArticlePreview = ({ article, onRemove }) => (
    <div className="relative mt-4 border rounded-lg  " dir="rtl">
      <button
        onClick={onRemove}
        className="absolute top-2 left-2 bg text-white bg-gray-700 rounded-full p-1"
      >
        <X className="w-4 h-4" />
      </button>
      <div className="flex flex-col ">
        {article.coverImage && (
          <img
            src={article.coverImage}
            alt="Article cover"
            className="w-full h-[200px] object-cover rounded-t-lg "
          />
        )}
        <div className="flex-1 bg-[#484948] rounded-b-lg px-4 min-h-[60px] pt-1">
          <h3 className=" text-lg text-white">{article.title}</h3>
          {article.subTitle && (
            <p className=" mt-2 text-white">{article.subTitle}</p>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <AlertDialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <AlertDialogTrigger asChild>
        <AiOutlineRetweet
          size={16}
          className="w-4 h-4  text-gray-600 hover:text-gray-900 transition-colors cursor-pointer"
        />
      </AlertDialogTrigger>
      <AlertDialogContent
        hideCloseButton={true}
        className="sm:max-w-[600px] p-6 rounded-2xl w-[95vw] min-h-fit "
        dir="rtl"
        onInteractOutside={(e) => e.preventDefault()}
      >
        <AlertDialogTitle className="hidden">
          Upload Media Dialog
        </AlertDialogTitle>
        <AlertDialogHeader className="flex flex-col p-2">
          <div className="flex items-center gap-3">
            {user?.user?.profileImage ? (
              <Image
                width={53}
                height={53}
                src={user.user.profileImage}
                alt="User"
                className="size-[53px] rounded-full"
              />
            ) : (
              <div className="size-[53px] rounded-full bg-gradient-to-br from-purple-50 to-pink-50 flex items-center justify-center">
                <span className="text-sm font-medium text-purple-600">
                  {user?.user?.name?.charAt(0) || 'A'}
                </span>
              </div>
            )}
            <div className="flex flex-col gap-2 justify-end">
              <h3 className="text-[18px] font-semibold text-right text-[#65656B]">
                {user?.user?.name || 'username'}
              </h3>
            </div>
          </div>
        </AlertDialogHeader>
        <ScrollArea
          className={`${images.length || video || articlePreview ? 'h-[330px]' : 'h-[150px]'}`}
        >
          <textarea
            onPaste={handlePaste}
            ref={textareaRef}
            value={content}
            onChange={(e) => setContent(e.target.value)}
            dir="rtl"
            placeholder="ماذا يخطر ببالك؟"
            className="w-full h-[150px] p-4 text-right text-gray-900 !border-none focus:ring-0 ring-0 !outline-non focus:outline-none resize-none"
          />

          {(images.length || video) && (
            <UploadMediaCarousel
              image={images}
              removeImage={removeImage}
              video={video}
              removeVideo={removeVideoFromS3}
            />
          )}
          {/* Show loading state */}
          {isLoadingArticle && (
            <div className="text-center p-4">جاري تحميل المقال...</div>
          )}
          {/* Show article preview */}
          {articlePreview && (
            <ArticlePreview
              article={articlePreview}
              onRemove={removeArticlePreview}
            />
          )}
        </ScrollArea>

        <div className="flex justify-between items-center gap-3 mt-4 flex-wrap">
          <div className="flex items-center gap-4 mt-2 text-gray-500">
            {/* <Bold className="w-5 h-5 cursor-pointer hover:text-gray-700" /> */}
            {/* <Link className="w-5 h-5 cursor-pointer hover:text-gray-700" /> */}
            <label className="cursor-pointer">
              <ImageIcon className="w-5 h-5 hover:text-gray-700" />
              <input
                type="file"
                accept="image/*"
                multiple
                className="hidden"
                disabled={isUploadingMedia}
                onChange={handleImageUpload}
              />
            </label>
            <label className="cursor-pointer">
              <VideoIcon className="w-5 h-5 hover:text-gray-700" />
              <input
                disabled={isUploadingMedia}
                type="file"
                accept="video/*"
                className="hidden"
                onChange={handleVideoUpload}
              />
            </label>
          </div>
          <div className="flex gap-2">
            <Button
              onClick={handleCloseDialog}
              className="bg-gray-300 text-gray-700 px-6 py-2 rounded-lg w-[75px] h-[31px]"
            >
              إلغاء
            </Button>
            <Button
              onClick={handleCreatePost}
              disabled={
                isUploadingMedia ||
                (images.length === 0 &&
                  !video &&
                  content.trim() === '' &&
                  !articlePreview)
              }
              className={`bg-purple-600 text-white px-6 py-2 rounded-lg w-[75px] h-[31px] ${isUploadingMedia ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {isPosting ? '...نشر' : 'نشر'}{' '}
              {(isUploadingMedia || isPosting) && <Loader size="sm" />}
            </Button>
          </div>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
}
